.field-group {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
}

.field-group span {
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.field-group__item {
  margin-top: 2px;
  cursor: pointer;
}
