.block {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  gap: 36px;
}

.iconBlock {
  min-width: 80px;
  height: 80px;
  background: #f3f6fc;
  position: relative;
  border-radius: 50%;
  z-index: 1;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  text-align: left;
}

.title {
  margin-bottom: 12px;
}

@media (max-width: 991px) {
  .block {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .content {
    text-align: center;
    max-width: 455px;
  }
}
