.loaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loaderContainer .loaderItem {
  top: 50%;
  left: 50%;
  z-index: 1;
  position: absolute;
  transform: translate(-50%, -50%);
}

.loaderSpinner {
  width: 63px;
  height: 63px;
  border-radius: 50%;
  border: 9px solid;
  border-color: rgba(73, 69, 255, 0.4);
  border-right-color: #4945ff;
  animation: s2 1s infinite linear;
}
@keyframes s2 {
  to {
    transform: rotate(1turn);
  }
}
