.dynamicBadge {
  background-color: rgb(26, 88, 223);
}

.staticBadge {
  background-color: rgb(226, 156, 4);
}

.addBadge {
  background-color: #198754;
  cursor: pointer;
}

.badgeEdit {
  margin: auto;
  margin-right: 7px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
.badgeEdit:hover {
  color: rgb(187, 187, 187);
}
