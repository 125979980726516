.block {
  position: relative;
  font-family: var(--poppins-font);
  margin-bottom: 40px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fafbfc;
  border-radius: 12px;
  padding: 28px 21px 28px 42px;
}

.text {
  text-align: left;
}

@media (max-width: 991px) {
  .card {
    padding: 15px;
  }
}
