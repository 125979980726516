.button {
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  padding: 10px 22px;
  border: none;
  width: 100px;
  border-radius: 100px;
  text-transform: uppercase;
}

.positiveButton {
  background-color: var(--positive-button-bg-color);
  color: var(--positive-button-text-color);
}

.negativeButton {
  background-color: var(--negative-button-bg-color);
  border: 1px solid var(--dodger-blue);
  color: var(--negative-button-text-color);
}

@media (max-width: 991px) {
  .button {
    width: unset;
  }
}
