.header{
    position: sticky;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #ECE9EF;
    background-color: var(--white);
}

.innerButtonsAuth{
    display: flex;
    align-items: center;
    gap: 20px;
}

@media (max-width: 991px) {
    .header{
        padding: 7px 0;
    }
    .desktopItems{
        display: none;
    }
}
