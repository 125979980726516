.hamburger{
    display: none;
    width: 36px;
    height: 26px;
    cursor: pointer;
    padding: 5px;
}

.line {
    height: 2px;
    width: 100%;
    background-color: var(--burger-button-color);
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
}

.line:nth-child(2) {
    margin: 5px 0;
}

.hamburgerActive .line:nth-child(1) {
    transform: translateY(7px) rotateZ(-45deg);
}

.hamburgerActive .line:nth-child(2) {
    opacity: 0;
}

.hamburgerActive .line:nth-child(3) {
    transform: translateY(-7px) rotateZ(45deg);
}

@media (max-width: 991px) {
    .hamburger{
        display: block;
    }
}
