.block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 40px;
  gap: 10px;
  max-width: 530px;
  background: #fafbfc;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.header h3 {
  margin: auto;
}

p.titleHidden {
  display: none;
}

p.title {
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.292683px;
  color: #101828;
}

.text {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  text-align: left;
  color: #000000;
  mix-blend-mode: normal;
}

.text > span {
  color: var(--dodger-blue);
  font-weight: 600;
}

.titleBlock {
  align-items: flex-start;
  padding: 5px 12px;
  height: 30px;
  background: #4945ff;
  border-radius: 4px;
}

.titleBlock p {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.292683px;
  color: #ffffff;
}

.tooltipItem {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipItem .tooltip {
  visibility: hidden;
  width: max-content;
  background-color: #7787a6;
  color: #fff;
  text-align: center;
  padding: 8px;
  top: 100%;
  left: 50%;
  font-size: 14px;
  line-height: 22px;
  transform: translateX(-50%);
  border-radius: 4px;
  position: absolute;
  font-weight: 400;
  z-index: 1;
  margin-top: 8.75px;
  text-transform: none;
}

.tooltipItem:hover .tooltip {
  visibility: visible;
}

.tooltipItem .tooltip::after {
  content: ' ';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -4px;
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent #7787a6 transparent;
}

@media (max-width: 991px) {
  .block {
    padding: 30px 28px;
    max-width: none;
    border-radius: 0;
  }

  .text {
    text-align: center;
  }

  .offerExample {
    display: none;
  }

  .tooltipItem {
    display: none;
  }

  p.titleHidden {
    display: block;
    margin: auto;
  }

  .header {
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .headerIcon {
    width: 46px;
    margin: auto;
  }
}
