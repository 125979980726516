.footer {
  padding: 64px 0 32px;
  background-color: var(--footer-bg);
  color: var(--white);
}

.innerFooterNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 445px;
  width: 100%;
  margin: 35px 0 63px;
}

.navigateFooterItem {
  position: relative;
  font-size: 13px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.navigateFooterItem:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 4px;
  border-radius: 2px;
  background-color: var(--white);
  transition: width 0.5s;
}

.navigateFooterItem:hover:before {
  width: 100%;
}

.footerContent {
  padding-top: 27px;
  border-top: 1px solid rgba(255, 255, 255, 0.49);
  justify-content: space-between;
}

.socials {
  display: flex;
  align-items: center;
  gap: 13px;
  max-width: 137px;
  width: 100%;
}

.socialItem {
  transition: opacity 0.3s;
}

.socialItem:hover {
  opacity: 0.7;
}

.footer .footerCopyright {
  font-size: 14px;
  line-height: 21px;
  color: var(--white);
}

.footerTermsConditions {
  font-size: 14px;
  line-height: 21px;
}

@media (max-width: 991px) {
  .footer {
    padding: 40px 0;
  }
  .footerLogo {
    margin-bottom: 38px;
  }
  .innerFooterNavigation {
    display: none;
  }
}

@media (max-width: 768px) {
  .footerContent {
    justify-content: center;
  }
  .socials {
    display: none;
  }
  .footerTermsConditions {
    display: none;
  }
}
