.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fafbfc;
  border-radius: 12px;
  padding: 28px 21px 28px 42px;
  max-width: 420px;
}

.titleBlock {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.iconBlock {
  min-width: 56px;
  height: 56px;
  background: #d6e3ff;
  position: relative;
  border-radius: 50%;
  z-index: 1;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  text-align: left;
}

.title {
  margin: auto 0;
  word-break: inherit;
  text-align: left;
}

@media (max-width: 991px) {
  .block {
    padding: 28px 10px 28px 42px;
    gap: 24px;
    width: 100%;
  }

  .block p {
    text-align: left;
    padding-right: 33px;
  }
}
