.howItWorksContainer {
  width: 631px;
  text-align: left;
  margin-top: 155px;
  margin-bottom: 121px;
}

.titlesSlider .slick-track {
  top: -50px;
}

.titlesSlider .slick-slider {
  margin-top: 60px;
}

h2.howItWorksTitle {
  margin-bottom: 18.49px;
}

p.howItWorksDescription {
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
  color: #101828;
  letter-spacing: -0.292683px;
}

.titlesSliderContent {
  padding: 50px 0;
}

.innerSliderItem {
  position: relative;
}

.titlesSliderItem {
  margin-left: 37px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
  margin-top: 70px;
}

.titlesSlider .slick-list {
  height: 435px !important;
  margin-top: -50px;
}

.titlesSlider .slick-slide:first-child .titlesItemLine.before {
  opacity: 0;
}

.titlesSlider .slick-slide:last-child .titlesItemLine.after {
  opacity: 0;
}

.slick-center .titlesItemLine {
  opacity: 1;
}

.titlesItemLine {
  position: absolute;
  width: 1px;
  background: #d0dbeb;
  height: 70px;
  opacity: 0;
  z-index: 1;
}

.titlesItemLine.after {
  left: 56px;
  top: 100%;
}

.titlesItemLine.before {
  left: 56px;
  top: 0;
}

.titlesSliderDot {
  min-width: 40px;
  height: 40px;
  margin: auto 0;
  background: #d2d1ff;
  border-radius: 50%;
  position: relative;
}

.titlesSliderDotNumber {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}

p.titlesSliderItemText {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  max-width: 380px;
  color: #808080;

  mix-blend-mode: normal;
}

.titlesSliderButtons {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: max-content;
}

.titlesSliderPageDots {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 4px;
  top: 50%;
  right: 0;
  gap: 5.58px;
  transform: translateY(-50%);
  height: max-content;
}

.titlesSliderButton {
  display: flex;
  gap: 8px;
  padding: 13px 24.5px;
  width: unset;
}

.titlesSliderArrow {
  margin: auto;
  min-width: 20px;
}

.slick-current .titlesSliderDot {
  background: #4945ff;
}

.slick-current .titlesSliderItemText {
  color: #000000;
}

.titlesSliderPage {
  background: #d2d1ff;
  border-radius: 2px;
  transform: rotate(90deg);
  width: 20.93px;
  height: 4px;
}

.slick-active .titlesSliderPage {
  background: #4945ff;
}

.imagesSlider {
  left: 60%;
  min-height: 677px;
  width: 100%;
  position: absolute;
  top: -42px;
}

.imagesSliderItem {
  height: 719px;
  width: auto;
  border-radius: 16px;
}

.titlesTextSlider {
  display: none;
}

.howItWorksGetStarted {
  margin-bottom: 20px;
  padding: 20px 40px;
  max-width: 300px;
  width: 100%;
  z-index: 1;
}

.howItWorksPaymentsBlock {
  left: 20px;
  bottom: 20px;
  position: absolute;
  display: flex;
  gap: 8px;
}

.howItWorksVisaMastercardLogo {
  width: 130px;
}

.howItWorksLiqpayLogo {
  width: 130px;
}

@media (max-width: 1120px) {
  .titlesSlider {
    height: 64px;
  }

  p.howItWorksDescription {
    font-size: 15px;
    margin-bottom: 33px;
    line-height: 22px;
    letter-spacing: unset;
  }

  h2.howItWorksTitle {
    margin: 33px 0 16px;
  }

  p.titlesSliderItemText {
    display: none;
    width: 0;
    height: 0;
  }

  .titlesItemLine.before {
    transform: rotate(90deg) translateX(50%);
    top: 38%;
    left: 0;
  }

  .titlesItemLine.after {
    transform: rotate(90deg) translateX(50%);
    left: 100%;
    top: 38%;
  }

  .titlesSliderItem {
    margin: 50px 0 0 0;
    width: 140px;
    justify-content: center;
  }

  .howItWorksContainer {
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 33.4px;
  }

  .titlesSliderPageDots {
    display: none;
  }

  .titlesSliderButtons {
    margin: auto;
  }

  .imagesSlider {
    margin-bottom: 24px;
    min-height: unset;
    position: unset;
  }

  .titlesSlider .slick-slide {
    width: 140px;
  }

  .titlesSlider .slick-track {
    justify-content: center;
  }

  .titlesSlider .slick-list {
    height: unset !important;
    width: 320px;
    margin: 0 auto;
  }

  .imagesSliderItem {
    height: auto;
    width: 100%;
  }

  .titlesSlider .slick-slider {
    margin: 0;
  }

  .titlesTextSlider {
    display: block;
    margin-bottom: 24px;
  }

  .howItWorksGetStarted {
    margin-bottom: 100px;
  }

  .howItWorksPaymentsBlock {
    left: 50%;
    transform: translateX(-50%);
  }
}
