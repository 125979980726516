.innerMenuList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 560px;
  width: 100%;
  gap: 20px;
}

.itemMenuList {
  position: relative;
  cursor: pointer;
}

.itemMenuList:before {
  content: '';
  position: absolute;
  width: 0;
  height: 4px;
  background-color: var(--black);
  border-radius: 2px;
  transition: all 0.4s;
  bottom: -9px;
  left: 0;
}

.itemMenuList:hover:before {
  width: 100%;
}

.itemMenuLink {
  display: block;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  color: var(--black);
  text-transform: uppercase;
  transition: color 0.4s;
}

.activeItemMenuLink .itemMenuLink {
  color: var(--dodger-blue);
}

.activeItemMenuLink .itemMenuLink:hover {
  color: var(--dodger-blue);
}

.activeItemMenuLink:before {
  width: 100%;
  background-color: var(--dodger-blue);
}

@media (max-width: 991px) {
  .innerMenuList {
    max-width: unset;
  }
  .itemMenuList {
    width: 100%;
    text-align: center;
  }
  .itemMenuList:before {
    display: none;
  }
  .itemMenuLink {
    font-size: 16px;
  }
}
