.footer {
  background-color: var(--footer-bg);
  color: var(--white);
  padding: 24px;
}

.footer > * {
  font-family: var(--poppins-font);
}

.footerContent {
  padding-top: 14px;
  justify-content: space-between;
}

.socials {
  display: flex;
  align-items: center;
  gap: 13px;
  max-width: 137px;
  width: 100%;
}

.socialItem {
  transition: opacity 0.3s;
}

.socialItem:hover {
  opacity: 0.7;
}

.footer .footerCopyright {
  font-size: 14px;
  line-height: 21px;
  color: var(--white);
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}

.footerTermsConditions {
  font-size: 14px;
  line-height: 21px;
  text-decoration: inherit;
  color: inherit;
}

.footerTermsConditions:hover {
  color: inherit;
}

.disabled {
  pointer-events: none;
}

@media (max-width: 991px) {
  .footer {
    padding: 40px 0;
  }
  .footerLogo {
    margin-bottom: 38px;
  }
  .innerFooterNavigation {
    display: none;
  }
}

@media (max-width: 768px) {
  .footerContent {
    justify-content: center;
  }
  .socials {
    display: none;
  }
  .footerTermsConditions {
    display: none;
  }
}
