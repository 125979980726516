.examplesBlock {
  margin: 67.49px 0 94px;
}

.examplesSliderItem {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(389px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 60px;
  padding: 20px;
  align-items: start;
  margin-bottom: 68.75px;
}

.examplesBlock .slider-wrapper {
  width: calc(100% + 30px);
  padding: 0 15px;
  margin-left: -15px;
  overflow: hidden;
}

.examplesBlock .slick-dots li {
  height: auto;
}

.examplesSliderPage {
  background: #d2d1ff;
  border-radius: 2px;
  height: 4px;
}

.slick-active .examplesSliderPage {
  background: #4945ff;
}

.examplesSliderDots {
  display: flex;
  gap: 5.58px;
  justify-content: center;
  align-items: center;
  height: 29px;
  padding-left: 0;
}

.examplesSliderDots li {
  margin: 0;
  width: 20px;
}

.examplesSliderActionsBlock {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 369px;
}

.examplesSliderButton {
  display: flex;
  gap: 8px;
  padding: 13px 24.5px;
  width: unset;
}

.examplesSliderArrow {
  margin: auto;
  min-width: 20px;
}

@media (max-width: 991px) {
  .examplesSliderItem {
    padding: 20px 0;
    grid-template-columns: none;
  }

  .examplesBlock {
    margin-top: 33px;
  }

  .examplesSliderItem {
    margin-bottom: 80px;
  }

  .examplesSliderActionsBlock {
    padding: 0 23px;
  }

  .examplesSliderDots {
    gap: 4.22px;
  }

  .examplesSliderDots li {
    width: unset;
    max-width: 20px;
    min-width: 15.83px;
  }
}

@media (max-width: 355px) {
  .examplesSliderArrowText {
    display: none;
  }

  .examplesSliderButton {
    padding: 13px 15px;
  }

  .examplesSliderActionsBlock {
    max-width: 300px;
  }
}
