.header {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #ece9ef;
  background-color: var(--white);
}
@media (max-width: 991px) {
  .header {
    padding: 7px 0;
  }
}
