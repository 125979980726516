.pricingItem {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 12px;
}

.pricingItem:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.pricingItem .pricingHeader {
  background-color: #111;
  color: white;
  font-size: 25px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.pricingItem li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
}

.pricingItem .grey {
  background-color: #eee;
  font-size: 20px;
}

.pricingColumns {
  float: left;
  width: 50%;
  padding: 8px;
  border-radius: 12px;
}

@media only screen and (max-width: 600px) {
  .pricingColumns {
    width: 100%;
  }
}
