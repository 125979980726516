@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --poppins-font: 'Poppins', sans-serif;
  --dodger-blue: #4945ff;
  --white: #ffffff;
  --black: #000000;
  --title-positive-color: #101828;
  --text-color: #000000;
  --burger-button-color: #292d32;
  --footer-bg: #211a34;
  --container-width: 1238px;

  --positive-button-bg-color: #4945ff;
  --positive-button-text-color: #ffffff;
  --negative-button-bg-color: #ffffff;
  --negative-button-text-color: #4945ff;
}

html {
  height: 100%;
}

.landing section {
  scroll-margin-top: 74px;
}

.landing {
  position: relative;
  font-family: var(--poppins-font);
  height: 100%;
  background-color: var(--white);
  margin-top: 0;
}

.landing > * {
  font-family: var(--poppins-font);
}

.landing a {
  text-decoration: inherit;
  color: inherit;
}

.landing a:hover {
  color: inherit;
}

.landing ul {
  margin: 0;
}

.landing li {
  list-style: none;
}

.landing h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: -1px;
  color: var(--title-positive-color);
}

.landing h2 {
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
  letter-spacing: -0.4px;
  color: var(--title-positive-color);
}

.landing h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--text-color);
  text-transform: uppercase;
}

.landing p {
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  color: var(--text-color);
  margin: 0;
}

.landing .layoutWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.landing .container {
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.overflow-clip {
  overflow: clip;
}

/*HOME_HEAD*/
.homeHead {
  margin-top: 67px;
}

h1.homeHeadTitle {
  max-width: 1067px;
  margin: 0 auto;
  text-align: center;
}

.homeHeadTitle > span {
  color: var(--dodger-blue);
}

.blockTitle > span {
  color: var(--dodger-blue);
}

.aboutUsText > span {
  color: var(--dodger-blue);
}

h2.examplesTitle {
  margin-bottom: 8px;
}

p.supHomeHeadTitleText {
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
  max-width: 620px;
  text-align: center;
  margin: 24px auto 20px;
}

p.examplesDescription {
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: -0.292683px;
}

.getStartedButton {
  width: 150px;
}

.getStartedButton.hidden {
  visibility: hidden;
}

.homeHeadButton {
  display: block;
  min-width: 200px;
  width: 100%;
}

.homeHeadImage {
  max-width: 670px;
  width: 100%;
  margin: 0 auto;
}

.homeHeadCloud {
  position: absolute;
  z-index: 0;
}

.homeHeadCloud:nth-child(1) {
  top: 18%;
  right: 0;
}

.homeHeadCloud:nth-child(2) {
  top: 10%;
  left: 0;
}

.homeHeadCloud:nth-child(3) {
  bottom: 33%;
  left: 10%;
}

.homeHeadCloud:nth-child(4) {
  bottom: 20%;
  right: 0;
}

/*YOU WILL*/
.youWillContainer {
  width: 100%;
  position: relative;
}

.youWillBlock {
  max-width: 475px;
}

.youWillItems {
  margin-top: 65px;
  margin-bottom: 130px;
}

.youWillImage {
  position: absolute;
  margin-top: -16px;
  top: 0;
}

/*DO YOU*/
.doYouBlock {
  width: 100%;
  background: linear-gradient(90.14deg, #070412 -53.26%, #130f23 -17.8%, #201933 11.2%);
  border: 1px solid #000000;
  display: flex;
  flex-direction: row;
  text-align: left;
}

.doYouContainer {
  position: relative;
  z-index: 1;
}

.doYouContent {
  margin-top: 75px;
  width: 100%;
  max-width: 874px;
  margin-left: auto;
  margin-bottom: 109px;
}

h2.doYouTitle {
  color: #ffffff;
  margin-bottom: 46px;
}

.doYouItems {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0px;
  gap: 34px;
  justify-content: center;
}

.doYouImage {
  width: 411px;
  height: 436px;
  position: absolute;
  z-index: -1;
  bottom: 47px;
  left: -64px;
}

.doYouCloud {
  position: absolute;
  z-index: 1;
  top: 67px;
  right: 134px;
}

.aboutUsBlock {
  margin: 60px 0 40px;
}

.aboutUsContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 50px;
}

.aboutUsCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fafbfc;
  border-radius: 12px;
  padding: 28px 21px 28px 42px;
}

.paymentsBlock {
  left: 20px;
  bottom: 60px;
  width: 100%;
  display: flex;
  gap: 8px;
}

.visaMastercardLogo {
  width: 130px;
}

.liqpayLogo {
  width: 130px;
}

.pricesBlock h3 {
  width: max-content;
  margin: 0 auto;
}

.aboutUsPrices {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  /*HOME HEAD*/
  .homeHead {
    margin: 25px 0;
  }
  h1.homeHeadTitle {
    font-size: 36px;
    line-height: 50px;
    max-width: 550px;
  }

  h2.blockTitle {
    font-size: 30px;
  }

  p.supHomeHeadTitleText {
    font-size: 15px;
    line-height: 22px;
    margin: 18px auto;
  }

  p.examplesDescription {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: unset;
  }

  .landing section {
    scroll-margin-top: 60px;
  }

  .homeHeadButton {
    min-width: 160px;
  }
  .homeHeadImage {
    padding: 0 27px;
    margin-top: 38px;
  }
  .homeHeadCloud:nth-child(1) {
    top: 17%;
    right: -61px;
  }
  .homeHeadCloud:nth-child(2) {
    top: 9%;
    left: -59px;
  }
  .homeHeadCloud:nth-child(3) {
    bottom: 23%;
    left: -40px;
  }
  .homeHeadCloud:nth-child(4) {
    bottom: 12%;
    right: -12px;
  }

  /*YOU WILL*/
  .youWillItems {
    margin-top: 33px;
    margin-bottom: 64px;
  }

  .youWillBlock {
    max-width: 100%;
    width: 100%;
    margin-top: 25px;
  }

  .youWillImage {
    display: none;
  }

  /*DO YOU*/
  .doYouBlock {
    text-align: center;
  }

  .doYouContent {
    margin-top: 45px;
    margin-bottom: 103px;
    margin-left: unset;
  }

  .doYouCloud {
    display: none;
  }

  h2.doYouTitle {
    margin-bottom: 22px;
    font-size: 30px;
  }

  h2.examplesTitle {
    margin-bottom: 16px;
  }

  .container.exampleContainer {
    padding: 0;
  }

  .paymentsBlock {
    justify-content: center;
  }

  .aboutUsPrices {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

@media (max-width: 922px) {
  .doYouImage {
    max-width: unset;
    bottom: 0;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}

@media (max-width: 640px) {
  .homeHeadCloud:nth-child(1) {
    height: 36px;
    width: 113px;
  }
  .homeHeadCloud:nth-child(2) {
    height: 52px;
    width: 113px;
  }
  .homeHeadCloud:nth-child(3) {
    height: 31px;
    width: 82px;
  }
  .homeHeadCloud:nth-child(4) {
    height: 21px;
    width: 56px;
  }
}

@media (max-width: 355px) {
  .getStartedButton {
    display: none;
  }
}

@media (max-width: 432px) {
  .aboutUsContent {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
